import { graphql, Link, StaticQuery } from "gatsby";
import parse from "html-react-parser";
import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import PlayIcon from "../assets/images/home/play-button-white.svg";
import Slide from "../components/utility/slide_content";
import { Link as ScrollLink } from "react-scroll";
import superchargeMsp from "../assets/images/superchargeMsp.svg";
import ssVirtual from "../assets/images/ssVirtual'23.svg";
import Fade from "react-reveal/Fade";
import bgvideo from "../assets/videos/Bg(1).mp4";
import CustomAccordion from "../components/common/accordian";
import Buttons from "../components/common/button";
import DropDown from "../components/common/dropdown-component/drop-down";
import { Element } from "react-scroll";
import Carousal from "../components/common/carousel-new";
import TabContainer from "../components/common/tabs";
import CarouselComponent from "../components/common/carousel-component";
import FormBuilder from "../components/common/FormBuilder/Form-Builder";
import handleFormSubmit from "../components/common/FormBuilder/Form-Function";
import Frame from "../components/common/frame";
import SVGIcon from "../components/common/SVGIcon";
import Navigation from "../components/navigation";
import "../styles/component/faq.scss";
import "../styles/component/pricing-faq.scss";
import "../styles/pages/Supersummit-2022.scss";
import "../styles/pages/supersummit-2023.scss";
import "../styles/pages/supercon-2022.scss";
import "../styles/component/popup.scss";
import "../styles/component/forms.scss";
import "../styles/pages/Virtual-2023.scss";
import lottie from "lottie-web/build/player/lottie_light.min.js";
import beeranimate from "../assets/images/animations/beeranimate.json";
import Laugh from "./../assets/images/laugh.svg";
import Popper from "./../assets/images/party-popper.svg";
import YoutubePlayer from "../components/common/Youtube-player";
import AnimatedNumber from "react-awesome-animated-number";
import axios from "axios";
import TrackVisibility from "react-on-screen";

// import bgvideo from "../assets/videos/Bg.mp4"

const VirtualOct2023 = () => {
  const [popUp, setPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  let [activeKey, setActiveKey] = useState(0);

  const loadRef = useRef();

  const [odoValue, setOdoValue] = useState("321");
  // let registrationCount = "000"

  // const [sticky, setSticky] = useState(true);
  // const [speakerBoxTop, setSpeakerBoxTop] = useState(0);

  // useEffect(() => {
  //   if (!speakerBoxTop) {
  //     setSpeakerBoxTop(
  //       document.getElementById("speaker-parent").getBoundingClientRect().top
  //     );
  //   }
  // }, []);

  useEffect(async () => {
    try {
      const response = await axios.post(
        "https://lambdaapi.superops.ai/forumupvote?path=counterValue",
        null,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      setOdoValue(
        response.data[0].eventTime && response.data[0].eventTime !== "undefined"
          ? response.data[0].eventTime
              .split("")
              .reverse()
              .join("")
          : "025"
      );
      // registrationCount = registrationCount.split("").reverse().join("")
    } catch (error) {
      // console.log("error", error);
    }
  }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleVisibility);
  //   return function cleanup() {
  //     window.removeEventListener("scroll", handleVisibility);
  //   };
  // }, []);

  const handleVisibility = () => {
    // const odometer = document
    //   .getElementById("id_odometer")
    //   .getBoundingClientRect();
    // const screenHeight = window.innerHeight;
    // if (screenHeight > odometer.top) {
    //   setOdoValue(registrationCount);
    // }
    // const speakerSection = document
    //   .getElementById("speaker-tracker")
    //   .getBoundingClientRect();
    // if (screenHeight > speakerSection.top) {
    //   setSticky(false);
    // } else {
    //   setSticky(true);
    // }
  };

  // const scrollToSpeaker = () => {
  //   window.scrollTo({
  //     top: speakerBoxTop + 140,
  //     behavior: "smooth",
  //   });
  // };

  loadRef.current = setLoading;
  function showpopup() {
    setPopUp(true);
  }
  // useEffect(() => {
  //   setTimeout(showpopup, 3000);
  // }, []);
  //   useEffect(() => {
  //   const anime=lottie.loadAnimation({
  //     container: document.querySelector(".text-center-anime"),
  //     loop: true,
  //     autoplay: true,
  //     renderer: "svg",
  //     animationData: beeranimate,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice",
  //    },
  //   })
  //   return () => anime.destroy()
  // })

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    // {
    //   name: "company",
    //   type: "text",
    //   label: "Company name",
    //   errorMessage: "Please enter a company name",
    //   required: false,
    // },
    // {
    //   name: "phone",
    //   type: "phone",
    //   label: "Mobile number",
    //   errorMessage: "Please enter a mobile number",
    //   required: false,
    // },
  ];

  const contentTemplate = (props) => {
    let { i, data } = props;
    return (
      <Fade bottom duration={800} delay={130 * i * 0.5} distance="40px">
        <div key={i} className="Speaker-card">
          {/* <Link to={`${data.slug}`}> */}
          <div className="position-relative mb20">
            <div className={`overflow-hidden track-${activeKey + 1}`}>
              <img
                className="speaker-img w-100"
                style={{ height: "auto !important" }}
                src={data.speakerImage.url}
                alt="img"
              />
            </div>
            {/* <span className="play-btn">
              <img className="play-icn" src={PlayIcon} alt="img" />
            </span> */}
          </div>

          {data.speakerBio && (
            <>
              <p className="fixed-title">TITLE</p>
              <span className="p18">
                <p className="title font-roboto">{data.speakerBio}</p>
              </span>
            </>
          )}

          <div className="name-container">
            <span className="fixed-title" style={{ lineHeight: "30px" }}>
              SPEAKER
            </span>
            <span className="p16">
              <p className="name">{data.speakerName}</p>
            </span>
          </div>
          {/* </Link> */}
        </div>
      </Fade>
    );
  };

  function MakeTabs(tabnames, contents) {
    return tabnames.map((tabname) => {
      let filteredContents = contents.filter(
        (content) => content.heroHeading === tabname
      );
      return {
        tabname: tabname,
        tabcontent: (
          <div delay={100}>
            {/* <h4>{filteredContents[0].heroSubheading}kok</h4> */}
            <div className="v-mobile">
              <Carousal template={contentTemplate} content={filteredContents} />
            </div>
            <div className="speaker-cards v-desk-flex">
              {filteredContents.map((cont, i) => {
                let Template = contentTemplate;
                return cont.videoLink && <Template key={i} i={i} data={cont} />;
              })}
            </div>
          </div>
        ),
      };
    });
  }

  const SpeakerCards = ({ tabnames, contents }) => {
    let filteredContents = tabnames.map((tabname) => {
      return contents.filter((content) => content.heroHeading === tabname);
    });
    return (
      <>
        <div className="v-mobile">
          <Carousal
            template={contentTemplate}
            content={filteredContents[activeKey]}
          />
        </div>
        <div className="speaker-cards v-desk-flex">
          {filteredContents[activeKey].map((cont, i) => {
            let Template = contentTemplate;
            return <Template key={i} i={i} data={cont} />;
          })}
        </div>
      </>
    );
  };

  async function handleSubmit(items) {
    const formData = items;
    setLoading(true);
    sessionStorage.setItem("superSummitName", items[0].value);
    sessionStorage.setItem("superSummitEmail", items[1].value);
    var successval = handleFormSubmit(
      formData,
      process.env.HUBSPOT_SUPERSUMMIT_VIRTUAL_2023_ENDPOINT,
      window.location.href,
      "Supersummit NewYork 2023 Subscription - Superops.ai",
      null,
      null,
      "success"
      // `success?refererName=${items[0].value}&&refererEmail=${items[1].value}`,
    );
    try {
      const response = await axios.post(
        "https://lambdaapi.superops.ai/forumupvote?path=counterUpdate",
        null,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      // console.log("error", error);
    }
    // successval === true && setSuccess(successval);
  }

  // async function handleSubmit(items) {
  //   let x = await formPromise(items).then(
  //     window.location = `/success?refererName=${items[0].value}&&refererEmail=${items[1].value}`
  //   )
  // }

  const Navbar = () => {
    return (
      <div className="Summit-nav-wrapper">
        <Container className="Summit-nav fixed-top">
          <div className="scroll-link d-dsk">
            <ScrollLink to="0">speakers</ScrollLink>
            <ScrollLink to="1">why attend</ScrollLink>
            <ScrollLink to="2">agenda</ScrollLink>
            <ScrollLink to="3">faq</ScrollLink>
          </div>
          <div>
            <SVGIcon name="logo-light" className="logo-light" />
          </div>
          <div className="d-dsk ">
            <Buttons
              theme="secondary white sm"
              text="REGISTER NOW"
              onClick={() => setPopUp(true)}
            />
          </div>
        </Container>
      </div>
    );
  };

  const InfoBox = (props) => {
    const { icon, info, heading } = props;
    return info.map((el, idx) => {
      return (
        <Col lg={2} xs={6}>
          <div className="info-box">
            <div>
              <img src={icon[idx].url} alt="icon" />
            </div>
            <div className="p14">
              <p>{el}</p>
            </div>
            <div className="p16">
              <p>{heading[idx]}</p>
            </div>
          </div>
        </Col>
      );
    });
  };

  const PopUpForm = () => {
    return (
      <div className="popup-signup">
        <div class="wrap position-fixed"></div>
        <Container className="position-fixed">
          <div className="Form-box position-relative">
            <SVGIcon
              name="close-icon-black"
              className="close-icon"
              onClick={() => setPopUp(false)}
            />
            {!success ? (
              <>
                <FormBuilder
                  formClassName="forms"
                  errorClassName="error-message"
                  inputErrorClassName="error-active"
                  data={formdata}
                  formmessage="Enter your details to register!"
                  buttonText={
                    loading ? (
                      <div className="loading-anim">
                        LOADING
                        <span className="ellipsis-anim">
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </span>
                      </div>
                    ) : (
                      "SUBMIT"
                    )
                  }
                  buttonClassName="primary pink"
                  onSubmit={(items) => handleSubmit(items)}
                  IpStackData
                />
                <div className="disclaimer p12 a12">
                  <center>
                    <p>
                      By clicking "SUBMIT", you agree to SuperOps's{" "}
                      <a href="https://superops.ai/terms" target="_blank">
                        Terms of use
                      </a>{" "}
                      and{" "}
                      <a href="https://superops.ai/privacy" target="_blank">
                        Privacy policy
                      </a>
                      .
                    </p>
                  </center>
                </div>
              </>
            ) : (
              <>
                <div className="success-box text-center">
                  <img src={Popper} alt="popper" />

                  <p className="ty">
                    Thanks for subscribing! <br /> We'll keep you posted.
                  </p>

                  <div className="p16">
                    <p>
                      {" "}
                      Before you go, why don't you take a look at our{" "}
                      {/* <span>
                        <img src={Laugh} alt="laugh" />
                      </span>{" "} */}
                      <br />{" "}
                      <a href="https://supersummit.com/jan-2022/">
                        on-demand virtual summit?
                      </a>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
    );
  };

  const Cards = (props) => {
    const { icons, heading, content } = props;
    return (
      <div className="Cards">
        <img className="icon" src={icons} />
        <p className="heading font-roboto">{heading}</p>
        {/* <p className="content">{content}</p> */}
      </div>
    );
  };

  // const SpeakerCards = (props) => {
  //   const { title, subtext, image } = props;
  //   return (
  //     <>
  //       <Col lg={4} xs={12}>
  //         <div className="speaker-card">
  //           <div className="speaker-image">
  //             <LazyLoad throttle={200} height={200}>
  //               <img src={image.url} alt="speaker" />
  //             </LazyLoad>
  //           </div>

  //           <p className="title font-roboto">{title}</p>

  //           <p className="subtext">{subtext}</p>
  //         </div>
  //       </Col>
  //     </>
  //   );
  // };

  function dropdownItems(titles) {
    return titles.map((title, i) => {
      return (
        <div className="dropdown-item" onClick={() => setActiveKey(i)}>
          <p>{title}</p>
        </div>
      );
    });
  }

  return (
    <div>
      <StaticQuery
        query={graphql`
          query EventLon2023 {
            SuperOps {
              pageComponents(
                where: { pageName: "SuperSummit - Virtual - 2023" }
              ) {
                hero {
                  heroExcerptList
                  heroExcerpt
                }
                seo {
                  keywords
                  description
                  title
                  image {
                    id
                    url
                  }
                }
                pageContents {
                  tag
                  heading
                  subtext {
                    html
                  }
                  detailIcon {
                    url
                  }
                  detailContent
                  detailHeading
                }
                faqs {
                  category
                  question
                  answerrt {
                    html
                  }
                }
                card {
                  cardHeading: heading
                  image {
                    url
                  }
                  title
                  subtext
                }
                images {
                  url
                }
              }
              webinars(where: { pageName: SuperCon }) {
                heroHeading
                slug
                heroSubheading
                speakerName
                speakerBio
                speakerImage {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
                time
                videoLink
                downloadFile {
                  url
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            {data.SuperOps.pageComponents.map((page) => {
              const { seo, hero, pageContents, faqs, card } = page;
              return (
                <div className="Supersummit-2022 Supersummit-2023 Supersummit-2024 virtual-2023">
                  <Frame
                    seo={seo}
                    FooterName="Super-Summit"
                    footerClassName="super-summit pt-0"
                    twitterOG
                  >
                    {popUp && <PopUpForm />}

                    <header>
                      <Navigation
                        page="Supersummit-2022"
                        tabs={[
                          { name: "why attend", id: "0" },
                          { name: "speaker", id: "1" },
                          { name: "agenda", id: "2" },
                          { name: "faq", id: "3" },
                        ]}
                        color={"transparent"}
                        setPopUp={setPopUp}
                      />
                    </header>

                    <section className="Hero position-relative">
                      <video
                        className="bg-video d-dsk"
                        autoPlay
                        muted
                        loop
                        id="bg-video"
                      >
                        <source
                          src={
                            "https://media.graphassets.com/ryeLL8C3RjKZo65LbVlN"
                          }
                          type="video/mp4"
                        />
                      </video>
                      <Container>
                        <div className="wrapper position-relative altv3 p18">
                          <img
                            src={ssVirtual}
                            className="hero-img down-to-up-1"
                            alt=""
                          />

                          <p className="description text-pink let-space-8 down-to-up-2">
                            {hero.heroExcerptList[0]}
                          </p>

                          <h2 className="font-roboto heading text-white down-to-up-3">
                            {hero.heroExcerptList[1]}
                          </h2>
                        </div>
                      </Container>
                    </section>

                    <div className="main-wrapper">
                      <section className="info-section">
                        <Container>
                          <center>
                            <Row className="justify-content-center down-to-up-4">
                              {[pageContents[0]].map((el, idx) => {
                                return (
                                  <InfoBox
                                    icon={el.detailIcon}
                                    info={el.detailHeading}
                                    heading={el.detailContent}
                                  />
                                );
                              })}
                            </Row>
                            {/* <div id="id_odometer"></div> */}

                            <div className="down-to-up-5">
                              <Buttons
                                theme="primary pink register-button"
                                text="REGISTER NOW FOR FREE"
                                onClick={() => setPopUp(true)}
                              />
                            </div>

                            <TrackVisibility offset={100}>
                              {({ isVisible }) =>
                                isVisible && (
                                  <div className="fade-in">
                                    <p className="p12 mt60 registration let-space-8">
                                      {hero.heroExcerpt}
                                    </p>
                                    <AnimatedNumber
                                      value={odoValue}
                                      size={45}
                                      duration={1500}
                                    />
                                  </div>
                                )
                              }
                            </TrackVisibility>
                          </center>
                        </Container>
                      </section>

                      <div id="0"></div>
                      <section className="why-attend">
                        <Container>
                          <Row className="justify-content-center">
                            <Col lg={10}>
                              <Fade bottom>
                                <p className="text-pink let-space-8 p14">
                                  {pageContents[1].heading}
                                </p>
                              </Fade>
                            </Col>
                            <Col lg={4}>
                              <Fade bottom>
                                <div className="altv3">
                                  <h1 className="font-roboto text-white mb50">
                                    {pageContents[1].detailHeading[0]}
                                  </h1>
                                </div>
                              </Fade>
                            </Col>
                            <Col lg={6}>
                              <Fade bottom>
                                {pageContents[1].detailContent.map((el) => {
                                  return <p className="points">{el}</p>;
                                })}
                              </Fade>
                            </Col>
                          </Row>
                        </Container>
                        <div id="1"></div>
                      </section>

                      <section className="SuperCon event" id="speaker-parent">
                        <Element name="speakers" className="Speakers-List">
                          <div className="position-relative outer-box mx-auto">
                            <div className={`outer-box-1`}>
                              <Col lg={11}>
                                <Row className="justify-content-between">
                                  <Col lg={5} className="altv3 p14">
                                    <Fade bottom>
                                      <p className="text-pink let-space-8">
                                        SPEAKERS
                                      </p>
                                      <h1 className="font-roboto text-white m-0">
                                        Learn from the best in the MSP industry
                                      </h1>
                                    </Fade>
                                  </Col>
                                  <Col lg={7} className="d-dsk">
                                    <Row className="h-100">
                                      {pageContents[4].detailHeading.map(
                                        (el, id) => {
                                          return (
                                            <Col
                                              lg={6}
                                              className={`d-flex justify-content-between flex-column ${
                                                id === activeKey
                                                  ? "active-track"
                                                  : ""
                                              }`}
                                            >
                                              <Fade bottom>
                                                <p
                                                  className={`text-pink let-space-8 p14 ${
                                                    id ? "opacity-0" : ""
                                                  }`}
                                                >
                                                  TRACK
                                                </p>
                                                <h1
                                                  className="font-roboto index-number"
                                                  onClick={() => {
                                                    setActiveKey(id);
                                                  }}
                                                >
                                                  0{id + 1}
                                                </h1>
                                                <p
                                                  className="tab-name fw-bold prelative m-0"
                                                  onClick={() =>
                                                    setActiveKey(id)
                                                  }
                                                >
                                                  {el}
                                                </p>
                                              </Fade>
                                            </Col>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </div>

                            <div className="d-mob outer-box-1">
                              <Row>
                                {pageContents[4].detailHeading.map((el, id) => {
                                  return (
                                    <div
                                      lg={3}
                                      className={`w-50 d-flex justify-content-between flex-column ${
                                        id === activeKey ? "active-track" : ""
                                      }`}
                                    >
                                      <Fade bottom>
                                        <p
                                          className={`mb50 text-pink let-space-8 p14 ${
                                            id ? "opacity-0" : ""
                                          }`}
                                        >
                                          TRACK
                                        </p>
                                        <h1
                                          className="font-roboto index-number mb30"
                                          onClick={() => {
                                            setActiveKey(id);
                                          }}
                                        >
                                          0{id + 1}
                                        </h1>
                                        <p
                                          className="tab-name fw-bold prelative m-0 p12"
                                          onClick={() => setActiveKey(id)}
                                        >
                                          {el}
                                        </p>
                                      </Fade>
                                    </div>
                                  );
                                })}
                              </Row>

                              {/* <DropDown
                                items={dropdownItems(
                                  pageContents[4].detailHeading
                                )}
                                currentVal={
                                  pageContents[4].detailHeading[activeKey]
                                }
                              /> */}
                            </div>

                            <div className="outer-box-2">
                              <SpeakerCards
                                tabnames={pageContents[4].detailHeading}
                                contents={data.SuperOps.webinars}
                              />
                              <div id="speaker-tracker"></div>
                            </div>
                          </div>
                          <div id="2"></div>
                        </Element>
                      </section>

                      {/* <section className="thank-partner">
                        <Container>
                          <Row className="parent justify-content-center">
                            <Col lg={4} className="altv3">
                              <h2 className="heading font-roboto">
                                {pageContents[2].heading}
                              </h2>
                            </Col>
                            {pageContents[2].detailIcon.map((item, idx) => {
                              return (
                                <Col lg={3}>
                                  <Link
                                    to={pageContents[2].detailHeading[idx]}
                                    target="blank"
                                  >
                                    <div className="img-wrapper d-flex justify-content-center align-items-center">
                                      <img
                                        src={item.url}
                                        alt="partners"
                                        className="w-100"
                                      />
                                    </div>
                                  </Link>
                                </Col>
                              );
                            })}
                          </Row>
                        </Container>
                      </section> */}

                      <section className="take-away">
                        <Container>
                          <Row className="justify-content-center">
                            <Col lg={10}>
                              <Fade bottom>
                                <p className="text-pink tag p14">
                                  {pageContents[3].tag}
                                </p>
                                <div className="altv3">
                                  <h1 className="font-roboto text-white heading">
                                    {pageContents[3].heading}
                                  </h1>
                                </div>
                              </Fade>
                              <Row>
                                <Col lg={5}>
                                  <Fade bottom>
                                    <div className="d-flex justify-content-center">
                                      <img
                                        src={pageContents[3].detailIcon[0].url}
                                        alt="super-summit-2023"
                                        className="image mb50"
                                      />
                                    </div>
                                  </Fade>
                                </Col>
                                <Col lg={7}>
                                  <Row className="row-gap-24">
                                    {pageContents[3].detailHeading.map(
                                      (el, id) => {
                                        return (
                                          <Col lg={6}>
                                            <Fade bottom>
                                              <p className="text-pink fw-bold p24 mb8">
                                                0{id + 1}
                                              </p>
                                              <p className="text-white fw-600 mb8">
                                                {el}
                                              </p>
                                              <p className="content p14 mb8">
                                                {
                                                  pageContents[3].detailContent[
                                                    id
                                                  ]
                                                }
                                              </p>
                                            </Fade>
                                          </Col>
                                        );
                                      }
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </section>

                      <div id="3"></div>
                      <section className="Faq-section">
                        <Container>
                          <div className="faq">
                            <div className="outer mx-auto">
                              <h2 className="faq-heading text-left font-roboto">
                                We are sure you have questions
                              </h2>
                              <CustomAccordion items={faqs} />
                            </div>
                          </div>
                        </Container>
                      </section>
                    </div>
                  </Frame>
                </div>
              );
            })}
          </>
        )}
      />
    </div>
  );
};

export default VirtualOct2023;